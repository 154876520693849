import React from 'react'
import Helmet from 'react-helmet'
const siteConfig = require("../../config.js");
const HrefLang = (props) => {
    const { slug } = props
    const otherLang = process.env.GATSBY_LANG === 'fr' ? 'en' : 'fr'
    return(
        <>
            <Helmet>
                <link rel="alternate" hrefLang={otherLang} href={`${siteConfig.domains[otherLang]}${slug}`} />
            </Helmet>
        </>
    )
}

export default HrefLang